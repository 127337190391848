import { graphql, Link } from 'gatsby';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Image from '../components/Image';
import InnovatorsFilter from '../components/InnovatorsFilter';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { renderResources } from '../utils/resource-helper';

/**
 * Extracts the resources from the data object and flattens it to an
 * array of resources (objects).
 *
 * @param {Object} data GraphQL query response
 *
 * @returns {Array} An array of resources (objects)
 */
const getRawResources = (data) =>
  data.judges.edges.reduce((acc, curr) => {
    acc.push(curr.node);
    return acc;
  }, []);

const Judges = ({ data, location }) => {
  const [filter, setFilter] = useState(null);
  const allResources = getRawResources(data);

  const filteredResources = filter
    ? allResources.filter((res) =>
        res.yearsJudging.some((item) => filter.includes(item))
      )
    : allResources;

  const increaseNumber = 24;
  const [list, setList] = useState([
    ...filteredResources.slice(0, increaseNumber),
  ]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(
    filteredResources.length > increaseNumber
  );

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  useEffect(() => {
    const all = queryString.parse(location.search);
    var yearsArray = null;
    try {
      yearsArray = all.years.split(' ').map(Number);
    } catch (error) {
      yearsArray = null;
    }
    setFilter(yearsArray);
  }, [location.search]);

  useEffect(() => {
    setList([...filteredResources.slice(0, increaseNumber)]);
  }, [filteredResources.length]);

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < filteredResources.length;
      const nextResults = isMore
        ? filteredResources.slice(currentLength, currentLength + increaseNumber)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < filteredResources.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <Layout title="Judges" className="annual-challenge">
      <div className="hero-container">
        <div className="hero-container__gradient bg-gradient-orange"></div>
        <div className="hero-container__titleImage">
          {/* Breadcrumbs */}
          <ul className="breadcrumbs">
            <li className="breadcrumb">
              <Link to="../../annual-challenge/about-the-challenge/">
                Annual Challenge
              </Link>
            </li>
            <li className="breadcrumb active">Finalists, Mentors and Judges</li>
          </ul>
          <h1 className="page-title">Finalists, Mentors and Judges</h1>
          <Image
            filename="hero-finalists-mentors-judges.png"
            className="hero-image"
          />
        </div>
      </div>
      {/* Finalists, Mentors and Judges Intro */}
      <Section id="innovators">
        <Container>
          <Row>
            <Column size={8} offset={2} className="mt-1">
              <h4 className="global-intro-text">
                The 2024 3M Young Scientist Challenge Finalists and State Merit Winners have been announced! Use the tabs below to check out their big ideas and explore all finalists, winners, and 3M Scientist mentors!
              </h4>
              <h2>Today’s Innovators</h2>
              <h4 className="global-intro-text">
                Get to know the diverse personalities of students, 3M Mentors,
                and others who have contributed their unique talents to the 3M
                Young Scientist Challenge. Use the tabs below to explore
                exciting ideas and inspirations from previous years’ challenges.
              </h4>
            </Column>
          </Row>
        </Container>
      </Section>

      {/* Filter */}
      <Section>
        <Container>
          <InnovatorsFilter location={location} />
        </Container>
      </Section>

      <Container>{renderResources('judges', list)}</Container>
      <Container className="pt-2">
        <div className="d-flex justify-content-center">
          {hasMore && (
            <Button onClick={handleLoadMore} className="load-more">
              Load More
            </Button>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export const data = graphql`
  query JudgesQuery {
    judges: allJudgesYaml(sort: { fields: yearsJudging, order: DESC }) {
      edges {
        node {
          cardImage
          id
          jobDivision
          jobTitle
          longDescription
          name
          prefix
          quoteImage
          shortDescription
          slug
          yearsJudging
        }
      }
    }
  }
`;

export default Judges;
